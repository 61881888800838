import * as React from "react"
import PropTypes from "prop-types"

import * as styles from "./button.module.scss"
import classNames from "classnames"

const Button = ({ href, children, size, ...otherProps }) => {
  const Tag = href ? 'a' : 'button';
  return (
    <Tag
      {...otherProps}
      {
        ...href ? { href: href } : {}
      }
      className={classNames(styles.root, {
        [styles[size]]: size
      })}
    >
      <span className={styles.inner}>
        {children}
      </span>
    </Tag>
  )
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string,
  size: PropTypes.string,
}

export default Button