import React from "react"
import { graphql, StaticQuery } from "gatsby"
import PropTypes from "prop-types"
import * as styles from "./community.module.scss"
import {  Container, Newsletter, SocialBorder } from ".."

const Community = ({ title, newsletter, html }) => {
  return (
    <section id="community" className={styles.root}>
      <Container>
        <div>
          <h2><span>{title}</span></h2>
          <div dangerouslySetInnerHTML={{ __html: html }} />
          <SocialBorder />
        </div>
        <Newsletter {...newsletter} />
      </Container>
    </section>
  )
}

const WrappedCommunity = props => (
  <StaticQuery
    query={graphql`
      query {
        markdownRemark(fileAbsolutePath: {regex: "/community/"}) {
          html
          frontmatter {
            title
            newsletter {
              title
              description
              btnLabel
            }
          }
        }
      }
    `}
    render={({ markdownRemark: { html, frontmatter } }) => <Community {...frontmatter} html={html} {...props} />}
  />
)

Community.propTypes = {
  title: PropTypes.string.isRequired,
  newsletter: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    btnLabel: PropTypes.string,
  }),
  html: PropTypes.string
}

export default WrappedCommunity;