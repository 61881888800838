import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import * as styles from "./features.module.scss"
import { childImageSharpType } from "../../types"
import { Container } from ".."

const Features = ({ features }) => {
  return (
    <section id="features" className={styles.root}>
      <Container>
        <div className={styles.features}>
          <h3>Features:</h3>
          <ul>
            {features.map(({title, subTitle, description, imgSrc: { childImageSharp: { fluid }}}) => {
              return (
                <li key={title}>
                  <strong>{subTitle}</strong>
                  <Img
                    fluid={fluid}
                    alt={title}
                    className={styles.img}
                  />
                  <h4>{title}</h4>
                  <p>{description}</p>
                </li>
              )
            })}
          </ul>
        </div>
      </Container>
    </section>
  )
}

const WrappedFeatures = props => (
  <StaticQuery
    query={graphql`
      query {
        markdownRemark(fileAbsolutePath: {regex: "/features/"}) {
          frontmatter {
            features {
              title
              subTitle
              description
              imgSrc {
                childImageSharp {
                  fluid(maxWidth: 495, maxHeight: 280) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={({ markdownRemark: { frontmatter } }) => <Features {...frontmatter} {...props} />}
  />
)

Features.propTypes = {
  features: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    subTitle: PropTypes.string,
    description: PropTypes.string,
    imgSrc: PropTypes.shape({
      childImageSharp: childImageSharpType,
    }),
  }))
}

export default WrappedFeatures;