import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import * as styles from "./media.module.scss"
import { childImageSharpType } from "../../types"
import { Container, Overlay } from ".."
import Icon from '../../icons/play.svg'

const Media = ({ title, media }) => {
  const [isOverlayOpen, setIsOverlayOpen ] = React.useState(false);
  const [currentMedia, setCurrentMedia ] = React.useState(0);
  const handleMediaClick = (e, index) => {
    e.preventDefault();
    setIsOverlayOpen(true)
    setCurrentMedia(index)
  }
  const onOverlayClose = () => {
    setIsOverlayOpen(false)
  }
  const onUpdateCurrent = (index) => {
    setCurrentMedia(index)
  }
  return (
    <section id="media" className={styles.root}>
      <Container>
        <h2>{title}</h2>
        <ul className={styles.media}>
          {media.map(({youtubeLink, imgSrc: { childImageSharp: { fluid }}}, index) => {
            return (
              <li key={index}>
                <a
                  href={youtubeLink ? youtubeLink : fluid.src}
                  className={styles.item}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => handleMediaClick(e, index)}
                >
                  <Img 
                    className={styles.img}
                    fluid={fluid}
                    alt={title}
                  />
                  { 
                    youtubeLink && <Icon className={styles.videoPlay} />
                  }
                  <span className={styles.border} />
                </a>
              </li>
            )
          })}
        </ul>
      </Container>
      <Overlay
        current={currentMedia}
        media={media}
        visible={isOverlayOpen}
        onClose={onOverlayClose}
        updateCurrent={onUpdateCurrent}
      />
    </section>
  )
}

const WrappedMedia = props => (
  <StaticQuery
    query={graphql`
      query {
        markdownRemark(fileAbsolutePath: {regex: "/media/"}) {
          frontmatter {
            title
            media {
              imgSrc {
                childImageSharp {
                  fluid(maxWidth: 1280, maxHeight: 720) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              youtubeLink
            }
          }
        }
      }
    `}
    render={({ markdownRemark: { frontmatter } }) => <Media {...frontmatter} {...props} />}
  />
)

Media.propTypes = {
  title: PropTypes.string,
  media: PropTypes.arrayOf(PropTypes.shape({
    imgSrc: PropTypes.shape({
      childImageSharp: childImageSharpType,
    }),
    youtubeLink: PropTypes.string
  }))
}

export default WrappedMedia;