import React from "react"
import PropTypes from "prop-types"
import { getIconByType } from "./icon"

const Icon = ({ type, className }) => {
  return (
    <svg className={className} role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%" gradientTransform="rotate(-45)">
          <stop offset="0%"   stopColor="#7159df"/>
          <stop offset="100%" stopColor="#319bfb"/>
        </linearGradient>
      </defs>
      {
        getIconByType(type)
      }
    </svg>
  )
}

Icon.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string
}

export default Icon
