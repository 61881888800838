import React from "react"
import * as styles from "./particles.module.scss"

const Particle = () => <div className={styles.particle}><div></div></div>

const Particles = () => {
  const particles = Array.apply(null, Array(100));
  return <div className={styles.root}>
    {particles.map((item, index) => <Particle key={index} />)}
  </div>
}

export default Particles;