import React from "react"
import { graphql, StaticQuery } from "gatsby"
import PropTypes from "prop-types"
import * as styles from "./about.module.scss"
import { Button, Overlay } from ".."
import { getButtonLabel } from "../../utils/content"
import Icon from "../../icons/play.svg"

const About = ({ title, button, html }) => {
  const [ isOverlayOpen, setIsOverlayOpen ] = React.useState(false);
  const handleTrailerClick = (event) => {
    event.preventDefault()
    setIsOverlayOpen(true)
  }
  const handleTrailerClose = () => {
    setIsOverlayOpen(false)
  }
  return (
    <section id="about" className={styles.root}>
      <div className={styles.content}>
        <h2>{title}</h2>
        <div dangerouslySetInnerHTML={{ __html: html }} />
        {
          button && <Button href={button.link} onClick={(e) => handleTrailerClick(e)}>
            <Icon className={styles.icon} />
            {getButtonLabel(button.label)}
          </Button>
        }
      </div>
      <Overlay
        media={[{
          youtubeLink: button.link
        }]}
        visible={isOverlayOpen}
        onClose={handleTrailerClose}
      />
    </section>
  )
}

const WrappedAbout = props => (
  <StaticQuery
    query={graphql`
      query {
        markdownRemark(fileAbsolutePath: {regex: "/about/"}) {
          html
          frontmatter {
            title
            subTitle
            button {
              label
              link
            }
          }
        }
      }
    `}
    render={({ markdownRemark: { frontmatter, html } }) => <About {...frontmatter} html={html} {...props} />}
  />
)

About.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  button: PropTypes.shape({
    label: PropTypes.string,
    link: PropTypes.string,
  }),
  html: PropTypes.string,
}

export default WrappedAbout;