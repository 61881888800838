import PropTypes from "prop-types"

export const childImageSharpType = PropTypes.shape({
  fluid: PropTypes.shape({
    base64: PropTypes.string,
    src: PropTypes.string.isRequired,
    srcSet: PropTypes.string,
    aspectRatio: PropTypes.number
  })
})

export const mediaType = PropTypes.arrayOf(PropTypes.shape({
  imgSrc: PropTypes.shape({
    childImageSharp: childImageSharpType,
  }),
  youtubeLink: PropTypes.string
}))