import * as React from "react"
import PropTypes from "prop-types"
import { Footer, Header } from '../'
import * as styles from "./layout.module.scss"

const Layout = ({ children }) => {
  return (
    <div className={styles.root}>
      <Header />
      <main className={styles.main}>{children}</main>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout