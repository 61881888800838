import React from "react"
import classnames from "classnames"
import PropTypes from "prop-types"

import Icon from "./iconBorder"
import { ICON_TYPES } from "./icon"
import Triangle from '../../icons/triangle.svg'
import * as styles from "./socialBorder.module.scss"

const SocialBorder = ({
  className,
}) => {
  return (
    <ul className={classnames(styles.social, className)}>
      <li>
        <a href="https://store.steampowered.com/developer/wolfire" target="_blank" rel="noopener noreferrer">
          <Icon type={ICON_TYPES.STEAM} className={styles.icon} />
          <Triangle className={styles.border} />
        </a>
      </li>
      <li>
        <a href="https://discordapp.com/invite/wCntgVQ" target="_blank" rel="noopener noreferrer">
          <Icon type={ICON_TYPES.DISCORD} className={styles.icon} />
          <Triangle className={styles.border}  />
        </a>
      </li>
      <li>
        <a href="https://www.youtube.com/channel/UCGFhIp3VXy90--3PfAqtSaA" target="_blank" rel="noopener noreferrer">
          <Icon type={ICON_TYPES.YOUTUBE} className={styles.icon} />
          <Triangle className={styles.border}  />
        </a>
      </li>
      <li>
        <a href="https://www.facebook.com/ReceiverGameOfficial/" target="_blank" rel="noopener noreferrer">
          <Icon type={ICON_TYPES.FACEBOOK} className={styles.icon} />
          <Triangle className={styles.border}  />
        </a>
      </li>
      <li>
        <a href="https://twitter.com/Wolfire" target="_blank" rel="noopener noreferrer">
          <Icon type={ICON_TYPES.TWITTER} className={styles.icon} />
          <Triangle className={styles.border}  />
        </a>
      </li>
    </ul>
  )
}

SocialBorder.propTypes = {
  className: PropTypes.string,
}

export default SocialBorder
