import React from "react"
import PropTypes from "prop-types"

import * as styles from "./newsletter.module.scss"
import { getButtonLabel } from "../../utils/content";
import { Button } from '..'

const Newsletter = ({ title, description, btnLabel }) => (
  <div className={styles.root}>
    <h3>{title}</h3>
    <p>{description}</p>
    <form
      action="https://wolfire.us3.list-manage.com/subscribe/post?u=5bc2fbddada9ebe48f22355f3&amp;id=ccc4bd6c81"
      method="post"
      name="mc-embedded-subscribe-form"
      target="_blank"
      noValidate
    >			
      <input
        type="email"
        name="EMAIL"
        className={styles.input}
        placeholder="Your email address"
        required 
      />
      <div className={styles.hidden} aria-hidden="true">
        <input type="text" name="b_5bc2fbddada9ebe48f22355f3_ccc4bd6c81" tabIndex="-1" />
      </div>
      <Button type="submit">{getButtonLabel(btnLabel)}</Button>
    </form>
  </div>
)

Newsletter.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  btnLabel: PropTypes.string,
};

export default Newsletter;
