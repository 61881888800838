import React from "react"
import classnames from "classnames"
import PropTypes from "prop-types"

import Icon, { ICON_TYPES } from "./icon"
import * as styles from "./social.module.scss"

const Social = ({
  className,
}) => {
  return (
    <ul className={classnames(styles.social, className)}>
      <li>
        <a href="https://store.steampowered.com/developer/wolfire" target="_blank" rel="noopener noreferrer">
          <Icon type={ICON_TYPES.STEAM} />
        </a>
      </li>
      <li>
        <a href="https://discordapp.com/invite/wCntgVQ" target="_blank" rel="noopener noreferrer">
          <Icon type={ICON_TYPES.DISCORD} />
        </a>
      </li>
      <li>
        <a href="https://www.youtube.com/channel/UCGFhIp3VXy90--3PfAqtSaA" target="_blank" rel="noopener noreferrer">
          <Icon type={ICON_TYPES.YOUTUBE} />
        </a>
      </li>
      <li>
        <a href="https://www.facebook.com/ReceiverGameOfficial/" target="_blank" rel="noopener noreferrer">
          <Icon type={ICON_TYPES.FACEBOOK} />
        </a>
      </li>
      <li>
        <a href="https://twitter.com/Wolfire" target="_blank" rel="noopener noreferrer">
          <Icon type={ICON_TYPES.TWITTER} />
        </a>
      </li>
    </ul>
  )
}

Social.propTypes = {
  className: PropTypes.string,
}

export default Social
