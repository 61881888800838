// extracted by mini-css-extract-plugin
export var noScroll = "overlay-module--noScroll--k2Fmc";
export var overlay = "overlay-module--overlay--MeniP";
export var content = "overlay-module--content--Gd6GC";
export var bg = "overlay-module--bg--OBq2D";
export var close = "overlay-module--close--x+O-J";
export var img = "overlay-module--img--b70zS";
export var video = "overlay-module--video--iPi4z";
export var btn = "overlay-module--btn--tBmYI";
export var prev = "overlay-module--prev--sYF2g";
export var next = "overlay-module--next--79RyU";