import React from "react"

import * as styles from "./footer.module.scss"
import { Container } from ".."

const Footer = () => (
  <footer className={styles.root}>
    <Container>
      <nav className={styles.nav}>
        <ul>
          <li>
            © {new Date().getFullYear()}&nbsp;
            <a href="http://www.wolfire.com" target="_blank" rel="noopener noreferrer">
              Wolfire Games
            </a>
          </li>
          <li><a href="http://blog.wolfire.com/" target="_blank" rel="noopener noreferrer">News</a></li>
          <li><a href="https://www.wolfire.com/contact" target="_blank" rel="noopener noreferrer">Contact</a></li>
          <li>
            Site designed by&nbsp;
            <a href="http://www.fullyillustrated.com" target="_blank" rel="noopener noreferrer">
              Fully Illustrated
            </a>
          </li>
        </ul>
      </nav>
    </Container>
  </footer>
)

export default Footer
