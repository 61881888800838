import * as React from "react"
import { About, Community, Features, Intro, Layout, Media, Seo } from "../components"
import * as styles from '../styles/home.module.scss'

const HomePage = () => {
  return (
    <Layout>
      <Seo title="Low Light Combat | Wolfire Games" />
      <Intro />
      <div className={styles.midSection}>
        <About />
        <Features />
        <Media />
        <Community />
      </div>
    </Layout>
  )
}

export default HomePage
