import React from "react"
import { graphql, StaticQuery } from "gatsby"
import PropTypes from "prop-types"
import * as styles from "./intro.module.scss"
import { Button } from ".."
import logoSrc from "./llc-logo@2x.png"
import Icon from "../../icons/llc.svg"
import { getButtonLabel } from "../../utils/content"
import Particles from "./particles/particles"

const Intro = ({ title, subTitle, button, platforms }) => {
  return (
    <section id="intro" className={styles.root}>
      <div className={styles.content}>
        <img src={logoSrc} alt={title} className={styles.logo} />
        {
          button && <Button href={button.link} size="lg">
            <Icon className={styles.icon} />
            {getButtonLabel(button.label)}
          </Button>
        }
        {
          subTitle && <p>{subTitle}</p>
        }
        {
          platforms && <ul>
            {
              platforms.map(platform => <li key={platform}>{platform}</li>)
            }
          </ul>
        }
      </div>
      <Particles />
    </section>
  )
}

const WrappedIntro = props => (
  <StaticQuery
    query={graphql`
      query {
        markdownRemark(fileAbsolutePath: {regex: "/intro/"}) {
          html
          frontmatter {
            title
            subTitle
            button {
              label
              link
            }
            platforms
          }
        }
      }
    `}
    render={({ markdownRemark: { frontmatter } }) => <Intro {...frontmatter} {...props} />}
  />
)

Intro.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  button: PropTypes.shape({
    label: PropTypes.string,
    link: PropTypes.string,
  }),
  platforms: PropTypes.arrayOf(PropTypes.string)
}

export default WrappedIntro;