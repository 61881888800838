import * as React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"

import * as styles from "./downloadButton.module.scss"

const DownloadButton = ({ isMobile }) => {
  return (
    <a href="/" className={classnames(styles.button, {
      [styles.isMobile]: isMobile
    })}>
      <span className={styles.inner}>
        <span className={styles.download} />
        <span className={styles.downloadHover} />
      </span>
    </a>
  )
}

DownloadButton.propTypes = {
  isMobile: PropTypes.bool
}

export default DownloadButton