import * as React from "react"
import classnames from "classnames"
import throttle from "lodash.throttle"
import { Link } from 'gatsby'

import { Social } from '../index'
import * as styles from "./header.module.scss"
import logoSrc from "./logo-@2x.png"
import { isMobile } from "../../utils/responsive"
import DownloadButton from "./downloadButton/downloadButton"

const SECTIONS = {
  INTRO: {
    name: "intro",
    label: 'Home',
    endY: 0,
  },
  ABOUT: {
    name: "about",
    label: "About",
    endY: 0,
  },
  FEATURES: {
    name: "features",
    label: "Features",
    endY: 0,
  },
  MEDIA: {
    name: "media",
    label: "Media",
    endY: 0,
  },
  COMMUNITY: {
    name: "community",
    label: "Community",
    endY: 0,
  },
}

const yThreshold = 250;

const Header = () => {

  const [state, setState] = React.useState({
    activeSection: null,
    observe: true,
    mobileNavOpen: false,
  })

  const observeTimer = React.useRef(null);

  const toggleMobileNav = () => {
    setState({
      ...state,
      mobileNavOpen: !state.mobileNavOpen
    });
  }

  const updateSectionY = () => {
    Object.keys(SECTIONS).forEach((section) => {
      const element = document.getElementById(SECTIONS[section].name);
      if(!element) return;
      const { top, height } = element.getBoundingClientRect();
      const endX = top + window.scrollY + height;
      SECTIONS[section].endY = endX;
    });
  }

  const observerCallback = React.useCallback(() => {
    if(state.observe === true) {
      return
    }
    setState({...state, observe: true});
   }, [state]);

  const handleClick = (event, id) => {
    event.preventDefault();
    const element = document.getElementById(id);
    element.scrollIntoView({behavior:"smooth", block: "start"});

    setState({
      ...state,
      activeSection: id,
      observe: false,
      mobileNavOpen: false,
    });
  }

  const getActiveSection = (yPos) => {
    if(yPos < SECTIONS.INTRO.endY - yThreshold) {
      return SECTIONS.INTRO.name;
    }
    if(yPos < SECTIONS.ABOUT.endY - yThreshold) {
      return SECTIONS.ABOUT.name;
    }
    if(yPos < SECTIONS.FEATURES.endY - yThreshold) {
      return SECTIONS.FEATURES.name;
    }
    if(yPos < SECTIONS.MEDIA.endY - yThreshold) {
      return SECTIONS.MEDIA.name;
    }
    return SECTIONS.COMMUNITY.name;
  }

  const memoizedHandleScroll = React.useCallback(
    () => {
      if(!state.observe) return;
      const { scrollY } = window;
      const activeSection = getActiveSection(scrollY);
      if(activeSection !== state.activeSection) {
        setState({
          ...state,
          activeSection
        })
      }
    },
    [state],
  );

  const throttleScroll = throttle(memoizedHandleScroll, 250);

  React.useEffect(() => {
    if(!window || isMobile()) return;
    window.addEventListener('scroll', throttleScroll, false);
    observeTimer.current = setTimeout(observerCallback, 850);
    updateSectionY();
    memoizedHandleScroll();

    return ()=> {
      clearTimeout(observeTimer.current);
      window.removeEventListener('scroll', throttleScroll, false);
    }
  }, [memoizedHandleScroll, observerCallback, throttleScroll])

  return (
    <header className={styles.root}>
      <div className={styles.container}>
        <Link to="/" className={styles.logo}>
          <img src={logoSrc} alt="Low Light Combat logo" />
        </Link>
        <nav className={classnames(styles.nav,
          {
            [styles.open]: state.mobileNavOpen
          })}>
          <ul className={styles.links}>
            {
              Object.keys(SECTIONS).map((section, index) => (
                <li key={`section_${index}`}>
                  <Link
                    to={`/#${SECTIONS[section].name}`}
                    onClick={(e) => handleClick(e, SECTIONS[section].name)}
                    className={classnames({
                      [styles.active]: state.activeSection === SECTIONS[section].name
                    })}
                  >
                    {
                      SECTIONS[section].name === "intro" 
                        ? <span className={styles.homeLink}>{SECTIONS[section].label}</span>
                        : SECTIONS[section].label
                    }
                  </Link>
                </li>
              ))
            }
          </ul>
          <Social />
          <DownloadButton />
        </nav>
        <DownloadButton isMobile />
        <button
          onClick={toggleMobileNav}
          type="button"
          className={classnames(styles.menu, {
            [styles.on]: state.mobileNavOpen
          })}
        >
          <span className={styles.burger}>
            <span className={styles.top} />
            <span className={styles.middle} />
            <span className={styles.extra} />
            <span className={styles.bottom} />
          </span>
        </button>
      </div>
    </header>
  )
}

export default Header